
function DayRasp(props){
    if(props.raspDay.length > 0){
        return(
            <div>
                {props.raspDay.map((e) => {
                    return(
                        <div className='row' key={e.id}>
                            <div className='time'>{e.time}</div>
                            <div>{e.replaced === 1 ? <span className="replaced">Замена</span> : ''}
                                <p>{e.subject_name}</p>
                                <p>{e.name}</p>
                            </div>
                            <div>Каб. {e.room_number}</div>
                        </div>
                    )
                })}
            </div>
        )
    }else{
        return(<div className="dayEmpty">
            Расписание еще не добавлено
        </div>)
    }
}

export default DayRasp