import './App.css';
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import Moment from "react-moment";
import NowEvent from './Components/NowEvent';
import NextEvent from './Components/NextEvents';
import qr from './Assets/qr1.png'
import { Link, Navigate  } from 'react-router-dom';


const quryUrl = 'api/'
const query = new URLSearchParams(window.location.search);
const cabId = query.get('id')

function AppF(props){
    const [cabName, setCabName] = useState('');
    const [rasp, setRasp] = useState([]);
    const [now, setNow] = useState([]);

    const refreshData = () => {
        getCabinetName()
        getCabinets()
        getNow()
    }
    
    const getCabinetName = () => {
        if(cabId !== null){
            axios.get(quryUrl + 'cabname/' + cabId).then((res) => {
                res.data.length > 0 ? setCabName(res.data[0].room_number) : setCabName('')
            })
        }
    }

    const getCabinets = () => {
        if(cabId !== null){
          axios.get(quryUrl + cabId).then((res) => {
            setRasp(res.data)
        })}
      }
    
    const getNow = () =>{
        if(cabId !== null){
            axios.get(quryUrl + 'now/' + cabId).then((res) => {
            setNow(res.data)
            })
        }
      }

    useEffect(() => {
        refreshData()
        setInterval(refreshData, 30000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(cabId !== null){
        return (
            <div className="App">
                <div className='headBlock'>
                    <div className='cabName'>
                        {'Кабинет ' + cabName}
                    </div>
                    <div className='currentTime'>
                        <div className="timeCaption">
                            Текущее время
                        </div>
                        <div className='timeClock'>
                            <Moment format="HH:mm" interval={1000} />
                        </div>
                    </div>
                    <div className='qrdiv'>
                        <img className="qr" src={qr} alt="qr" />
                    </div>
                </div>
                {rasp.length > 0 || now.length > 0 ?
                    <div className='grid'>
                        <NowEvent now={now.length > 0 ? now : rasp}
                            eventInProcess={now.length > 0 ? true : false}/>
                        <NextEvent rasp={rasp} />
                    </div> : 
                    <div className='headBlock'><span className="free">Кабинет свободен</span></div>
                }
                <div className='bottom'>
                    <Link className="bottomBlock" to="class">
                       Где мой класс?
                    </Link>
                </div>
            </div>
        )
    }else{
        return(
            <Navigate to={"class"} />
        )
    }

}

export default AppF;