import './App.css';
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import SelectSearch from 'react-select-search';
import Reload from './Components/Reload';
import { IoCloseCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DayRasp from './Components/DayRasp';

const quryUrl = 'api/'

const getWeeks = () => {
    let days = []
    const now = new Date()
    for(let i=1;i<6;i++){
        let date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + i)
        let ruDate = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) + '.' + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
        days.push({'date':date, 'ruDate': ruDate, 'timestamp': parseInt(date.getTime()/1000)})
    }
    return days
}

const weeks = getWeeks()

const WeekRasp = (props) => {
    const [classUnitId, setClassUnitId] = useState('')
    const [classUnitIds, setClassUnitIds] = useState([])
    const [classUnitName, setClassUnitName] = useState([])
    const [raspDays, setRaspDays] = useState('')

    useEffect(() => {
            axios.get(quryUrl + 'classes').then((res) => {
                setClassUnitIds(res.data)
            })
            if(localStorage.getItem('classUnitId') !== null){
                setClassUnitId(localStorage.getItem('classUnitId'))
                setClassUnitName(localStorage.getItem('classUnitName'))
            }
    },[])

    useEffect(() => {   
        if(classUnitId !== ''){
            axios.get(quryUrl + 'day/' + weeks[0].timestamp + '/classunit/' + classUnitId).then((res) => {
                setRaspDays(res.data)
            })
        }
      }, [classUnitId]); 

    const clearState = () => {
        setClassUnitId('')
        setClassUnitName('')
        localStorage.removeItem("classUnitId");
        localStorage.removeItem("classUnitName");
    }

    if(classUnitId === ''){
        return (
        <div className="App">
            <div className='headBlock'>
                <div className='cabName'>
                    Выберите класс
                    <SelectSearch placeholder="Выберите класс"
                            options={classUnitIds}
                            search={true}
                            value={classUnitId}
                            onChange={(val, obj) => {setClassUnitId(val)
                                setClassUnitName(obj.name)
                                localStorage.setItem('classUnitId', val)
                                localStorage.setItem('classUnitName', obj.name)}}/>
                </div>
            </div>
            <Reload />
        </div>)
    }else{
        if(Object.keys(raspDays).length > 0 ){
            console.log(raspDays)
            return (
            <div className="App">
                <div className='headBlock'>
                    <div className='cabName'>
                        {'Класс ' + classUnitName + ' '}
                        <IoCloseCircle onClick={() => {clearState()}}/>
                    </div>
                </div>
                
                <Tabs>
                    <TabList className='weekBlock'>
                        <Tab className='week_day'>ПН<br />{weeks[0].ruDate}</Tab>
                        <Tab className='week_day'>ВТ<br />{weeks[1].ruDate}</Tab>
                        <Tab className='week_day'>СР<br />{weeks[2].ruDate}</Tab>
                        <Tab className='week_day'>ЧТ<br />{weeks[3].ruDate}</Tab>
                        <Tab className='week_day'>ПТ<br />{weeks[4].ruDate}</Tab>
                    </TabList>
                    <div className='weekBlock'>
                    <div className='blockContent many'>
                        <TabPanel >
                            <DayRasp raspDay={raspDays['week_0']} />
                        </TabPanel>
                        <TabPanel>
                            <DayRasp raspDay={raspDays['week_1']} />
                        </TabPanel>
                        <TabPanel>
                            <DayRasp raspDay={raspDays['week_2']} />
                        </TabPanel>
                        <TabPanel>
                            <DayRasp raspDay={raspDays['week_3']} />
                        </TabPanel>
                        <TabPanel>
                            <DayRasp raspDay={raspDays['week_4']} />
                        </TabPanel>
                    </div>
                    </div>
                </Tabs>
                <Reload />
                <div className='bottom'>
                    <Link className="bottomBlock" to="/class">
                        Расписание на сегодня
                    </Link>
                </div>
            </div>
            )
        }else{
            return(<div></div>)
        }
    }
}

export default WeekRasp;