import './App.css';
import axios from 'axios';
import React from 'react';
//import Moment from "react-moment";
import SelectSearch from 'react-select-search';
import { IoCloseCircle } from 'react-icons/io5'
import NowEventClasses from './Components/NowEventClasses';
import NextEventsClasses from './Components/NextEventsClasses';
import Reload from './Components/Reload';
import { Link } from 'react-router-dom';

const quryUrl = 'api/'

class ClassRasp extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        classUnitIds : [],
        classUnitId: '',
        classUnitName: '',
        raspNow: [],
        raspNext: [],
        raspUpdated: false,
      }
      this.getNow = this.getNow.bind(this)
      this.getNext = this.getNext.bind(this)

      this.clearSatate = this.clearSatate.bind(this)
    }

    componentDidMount(){
      axios.get(quryUrl + 'classes').then((res) => {
        this.setState({classUnitIds: res.data})
      })
      if(localStorage.getItem('classUnitId') !== null){
        this.setState({ classUnitId: localStorage.getItem('classUnitId'), classUnitName: localStorage.getItem('classUnitName')})
      }
    }

    componentDidUpdate(){
      //console.log(this.state.classUnitId !== '')
      if(this.state.classUnitId !== '' && this.state.raspUpdated === false){
        this.getNow()
        this.getNext()
      }
    }

    clearSatate(){
      this.setState({
        classUnitId: '',
        classUnitName: '',
        raspNow: [],
        raspNext: [],
        raspUpdated: false
      })
      localStorage.removeItem("classUnitId");
      localStorage.removeItem("classUnitName");

    }

    getNow(){
      axios.get(quryUrl + 'classunit/now/' + this.state.classUnitId).then((res) => {
        this.setState({ raspNow: res.data })
        //console.log(res.data)
      })
    }

    getNext(){
      axios.get(quryUrl + 'classunit/' + this.state.classUnitId).then((res) => {
        this.setState({ raspNext: res.data,
                        raspUpdated: true })
        //console.log(res.data)
      })
    }

    render(){
      if(this.state.classUnitId === ''){
        return (
          <div className="App">
            <div className='headBlock'>
              <div className='cabName'>
                Выберите класс
                <SelectSearch placeholder="Выберите класс"
                              options={this.state.classUnitIds}
                              search={true}
                              value={this.state.classUnitId}
                              onChange={(val, obj) => {this.setState({ classUnitId: val, classUnitName: obj.name})
                              localStorage.setItem('classUnitId', val)
                              localStorage.setItem('classUnitName', obj.name)}}/>
              </div>
              
            </div>
            <Reload />
          </div>)
      }else{
        return (
          <div className="App">
            
            <div className='headBlock'>
              <div className='cabName'>
                {'Класс ' + this.state.classUnitName + ' '}
                <IoCloseCircle onClick={() => {this.clearSatate()}}/>
              </div>
              
            </div>
            {this.state.raspNext.length > 0 || this.state.raspNow.length > 0 ?
            <div className='grid'>
              <NowEventClasses now={this.state.raspNow.length > 0 ? this.state.raspNow : this.state.raspNext}
                        eventInProcess={this.state.raspNow.length > 0 ? true : false}/>
              <NextEventsClasses rasp={this.state.raspNext} />
            </div> : 
            <div className='headBlock'><span className="free">Уроки закончились</span></div>
            }
            <Reload />
            <div className='bottom'>
              <Link className="bottomBlock" to="/week">
                Расписание на неделю
              </Link>
            </div>
          </div>
        )
      }
  }
}

export default ClassRasp;
