import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppF from './AppF';
import ClassRasp from './ClassRasp';
import WeekRasp from './WeekRasp';
const devPath = ''
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter >
      <Routes>
        <Route path={devPath + "/"} element={<AppF devPath={devPath}/>} />
        <Route path={devPath + "/class"} element={<ClassRasp devPath={devPath} />} />
        <Route path={devPath + "/week"} element={<WeekRasp devPath={devPath} />} />

      </Routes>
    </BrowserRouter>
  </React.StrictMode>

);