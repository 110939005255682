import Moment from "react-moment";

function NowEventClasses(props) {
    if(props.now.length > 0){
        return(
            <div className='block'>
                <div className="blockHead">
                    {props.eventInProcess ? 'Сейчас: ' : <span>{timeLess(props.now[0].timestamp)}</span>}

                </div>
                {props.now.map((obj) => {
                    if(obj.timestamp === props.now[0].timestamp){
                        return(
                            <div className='blockContent' key={obj.id}>
                                <div className='nextEvent'>
                                <div className='eventClass str'><b>{props.now[0].replaced === 1 ? <span className="replaced big">Замена</span> : ''}{obj.subject_name}</b></div>
                                    <div className='eventClass str'>
                                        Кабинет: {obj.room_number}
                                    </div>
                                    <div className='eventTime str'>
                                        {'Время: '}
                                        <Moment unix date={obj.timestamp} format="HH:mm" />{' - '}
                                        <Moment unix date={obj.timestamp + obj.duration * 60} format="HH:mm" />
                                    </div>
                                    <div className='teacher'>
                                        <div className='eventImage small'>
                                            <img src={"https://school1298.ru/portal/workers/image.php?fio=" + obj.name} alt="Учитель"/>
                                        </div>
                                        <div className='eventTeacher str'>
                                            Учитель: <br/>{obj.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else{
                        return ''
                    }
                })}
            </div>
        )
    } else {
        return(
            <div className='block'>
                <div className="blockHead">
                    Сейчас: 
                </div>
                <div className='blockContent'>
                    <span className="free">Сейчас урока нет</span>
                </div>
            </div>
        )
    }
}

function timeLess(timestamp){
    let timeDifference = parseInt(- ((new Date().getTime() / 1000) - timestamp) / 60) + 1
    return('Через ' + timeDifference + ' ' + num_word(timeDifference, ['минуту', 'минуты', 'минут']) + ':')
}

function num_word(value, words){  
	value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return words[2]; 
	if(num > 1 && num < 5) return words[1];
	if(num === 1) return words[0]; 
	return words[2];
}

export default NowEventClasses


//{props.eventInProcess ? 'Сейчас: ' : <span>Через <Moment unix diff={parseInt(new Date().getTime()/1000)}>{props.now[0].timestamp}</Moment> минут</span>}
