function NextEventsClasses(props){
    if(props.rasp.length > 0){
        return(
            <div className='block'>
                <div className="blockHead">
                    Далее:
                </div>
                <div className='blockContent many'>
                    {props.rasp.map((e) => {
                        return(
                            <div className='row' key={e.id}>
                                <div className='time'>{e.time}</div>
                                <div>{e.replaced === 1 ? <span className="replaced">Замена</span> : ''}{e.subject_name} | {e.name}</div>
                                <div>Каб. {e.room_number}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className='block'>
                <div className="blockHead">
                    Далее: 
                </div>
                <div className='blockContent'>
                    <span className="free">Уроков нет</span>
                </div>
            </div>
        )
    }
}

export default NextEventsClasses