import { IoRefreshCircleOutline } from 'react-icons/io5'


const refresh = () => window.location.reload(true)


function Reload(props){
    return(
        <div className="refresh" onClick={refresh}>
            <IoRefreshCircleOutline className='refresh_icon'/>
        </div>
    )
}

export default Reload