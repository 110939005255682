function NextEvent(props){
    if(props.rasp.length > 0){
        return(
            <div className='block'>
                <div className="blockHead">
                    Далее:
                </div>
                <div className='blockContent many'>
                    {props.rasp.map((e) => {
                        return(
                            <div className='row' key={e.id}>
                                <div className='time'>{e.time}</div>
                                <div>{e.replaced === 1 ? <span className="replaced">Замена</span> : ''}{e.subject_name}</div>
                                <div>{JSON.parse(e.class_unit_names).map((el) => {
                                    if(el.indexOf('НДО') > -1){
                                        return (el.substring(0, el.indexOf('НДО') + 3) + ' ')
                                    } else {
                                        return (el + ' ')
                                    }
                                })}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className='block'>
                <div className="blockHead">
                    Далее: 
                </div>
                <div className='blockContent'>
                    <span className="free">Кабинет свободен</span>
                </div>
            </div>
        )
    }
}

export default NextEvent