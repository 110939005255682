import Moment from "react-moment";

function NowEvent(props) {
    if(props.now.length > 0){
        return(
            <div className='block'>
                <div className="blockHead">
                    {props.eventInProcess ? 'Сейчас: ' : <span>{timeLess(props.now[0].timestamp)}</span>}
                    <br/>
                    {props.now[0].replaced === 1 ? <span className="replaced big">Замена</span> : ''}{props.now[0].subject_name}
                </div>
                <div className='blockContent'>
                    <div className='nextEvent'>
                        <div className='eventClass str'>
                            
                            Класс: {JSON.parse(props.now[0].class_unit_names).map((el) => {
                                    if(el.indexOf('НДО') > -1){
                                        return (el.substring(0, el.indexOf('НДО') + 3) + ' ')
                                    } else {
                                        return (el + ' ')
                                    }
                                })
                            }
                        </div>
                        <div className='eventTime str'>
                            {'Время: '}
                            <Moment unix date={props.now[0].timestamp} format="HH:mm" />{' - '}
                            <Moment unix date={props.now[0].timestamp + props.now[0].duration * 60} format="HH:mm" />
                        </div>
                        <div className='teacher'>
                            <div className='eventImage'>
                                <img src={"https://school1298.ru/portal/workers/image.php?fio=" + props.now[0].name} alt="Учитель"/>
                            </div>
                            <div className='eventTeacher str'>
                                Учитель: <br/>{props.now[0].name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className='block'>
                <div className="blockHead">
                    Сейчас: 
                </div>
                <div className='blockContent'>
                    <span className="free">Кабинет свободен</span>
                </div>
            </div>
        )
    }
}

function timeLess(timestamp){
    let timeDifference = parseInt(- ((new Date().getTime() / 1000) - timestamp) / 60) + 1
    return('Через ' + timeDifference + ' ' + num_word(timeDifference, ['минуту', 'минуты', 'минут']) + ':')
}

function num_word(value, words){  
	value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return words[2]; 
	if(num > 1 && num < 5) return words[1];
	if(num === 1) return words[0]; 
	return words[2];
}

export default NowEvent


//{props.eventInProcess ? 'Сейчас: ' : <span>Через <Moment unix diff={parseInt(new Date().getTime()/1000)}>{props.now[0].timestamp}</Moment> минут</span>}
